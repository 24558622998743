<template>
  <div class="page-container me-page">
    <portal to="page-name">Meu perfil</portal>
    <div class="card">
      <div class="loading mt-2" v-if="loading" />
      <div class="card-header">
        <div class="card-title">
          Meu perfil
        </div>
      </div>
      <div class="card-body">
        <label class="form-label">Dados principais</label>
        <div class="me-group">
          <div class="columns">
            <div class="column col-6 form-group">
              <strong class="mr-2">Nome:</strong> {{ form.name }}
            </div>
            <div class="column col-6 form-group">
              <strong class="mr-2">Data nascimento:</strong> {{ form.birthDate | date }}
            </div>
            <div class="column col-6">
              <strong class="mr-2">
                CPF:
              </strong> {{ form.identity ? form.identity.value : '' | cpf }}
            </div>
            <div class="column col-6">
              <strong class="mr-2">Email:</strong> {{ form.email }}
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column col-12 form-group">
            <label class="form-checkbox">
              <input type="checkbox" v-model="changePassword">
              <i class="form-icon"/> Redefinir senha
            </label>
            <template v-if="changePassword">
              <div class="columns">
                <div class="column col-3 col-sm-12 form-group"
                     :class="{'has-error': $v.form.password.$error}">
                  <label for="password" class="form-label">Nova senha</label>
                  <input type="password" class="form-input" id="password"
                         v-model="form.password"
                         @change="$v.form.password.$touch()"
                            placeholder="senha">
                  <template v-if="$v.form.password.$error">
                    <p class="form-input-hint"
                       v-if="!$v.form.password.required">Campo obrigatório</p>
                    <p class="form-input-hint"
                       v-if="!$v.form.password.minLength">
                      A senha deve conter no mínimo 6 caracteres
                    </p>
                  </template>
                </div>
                <div class="column col-3 col-sm-12 form-group"
                     :class="{'has-error': $v.form.passwordConfirmation.$error}">
                  <label for="password-confirmation" class="form-label">
                    Confirme a nova senha
                  </label>
                  <input type="password" class="form-input" id="password-confirmation"
                         v-model="form.passwordConfirmation"
                         @change="$v.form.passwordConfirmation.$touch()"
                         placeholder="senha">
                  <template v-if="$v.form.passwordConfirmation.$error">
                    <p class="form-input-hint"
                       v-if="!$v.form.passwordConfirmation.required">
                      Campo obrigatório
                    </p>
                    <p class="form-input-hint"
                       v-else-if="!$v.form.passwordConfirmation.sameAsPass">
                      Senhas não conferem
                    </p>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary btn-icon btn-icon-left mr-1" @click="save()"
                :disabled="saving || !changePassword" :class="{loading: saving}">
          <fa-icon :icon="['fal', 'save']"/>
          Salvar
        </button>
        <router-link to="/" class="btn">Voltar</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/src/validators';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      path: '/me',
      loading: false,
      saving: false,
      changePassword: false,
      form: this.blankForm(),
    };
  },
  validations() {
    const rules = {
      form: {
        password: {},
        passwordConfirmation: {},
      },
    };

    if (this.changePassword) {
      rules.form.password = {
        required,
        minLength: minLength(6),
      };
      rules.form.passwordConfirmation = {
        required,
        sameAsPass: sameAs('password'),
      };
    }

    return rules;
  },
  watch: {
    changePassword: {
      handler() {
        this.clearPassword();
      },
    },
  },
  async mounted() {
    this.setData();
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  methods: {
    blankForm() {
      return {
        form: {
          id: '',
          name: '',
          birthDate: '',
          gender: '',
          email: '',
          identity: {
            type: '',
            value: '',
          },
          imageUrl: '',
          password: '',
          passwordConfirmation: '',
        },
      };
    },
    setData() {
      this.form = {
        id: this.user.id,
        name: this.user.name,
        birthDate: this.user.birthDate,
        gender: this.user.gender,
        email: this.user.email,
        identity: this.user.identity,
        imageUrl: this.user.imageUrl,
        password: '',
        passwordConfirmation: '',
      };
    },
    clearPassword() {
      this.$v.form.$reset();
      this.form.password = '';
      this.form.passwordConfirmation = '';
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return null;
      }

      this.saving = true;

      const data = {};

      if (this.form.password) {
        data.password = this.form.password;
      }

      return this.$http.post('/me', data)
        .then(() => {
          this.$toast.show('Salvo com sucesso!');
        })
        .catch(() => {})
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .me-page {
    max-width: 1024px;
    .me-group {
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      margin: $layout-spacing 0;
      padding: $layout-spacing;
    }
  }
</style>
